import React from "react";
import { graphql, Link } from "gatsby";
import { ArticleBackground } from "../components/layout/ArticleBackground";
import { Header, Footer } from "../components/layout/index";
import { BannerImage } from "../components/atoms/BannerImage";
import { Seo } from "../components/Seo";
import { truncateBody, getSiblingProps } from "../utils";
import { PortableTextComponents } from "../components/PortableTextComponents";
import { PortableText } from "@portabletext/react";
import { infoPath } from "../constants";

const InfoPost = ({ data, pageContext }) => {
  const { posts } = pageContext;
  const {
    post: { locale, title, id, body, description, publishedAt },
  } = data;
  const excerpt = truncateBody(body, 141);
  return (
    <>
      <Seo
        locale={locale}
        description={description || excerpt}
        isArticle={true}
      />
      <Header locale={locale} />
      <div className="relative px-4 sm:px-6 lg:px-8 py-16 overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div className="relative h-full text-lg" aria-hidden="true">
            <ArticleBackground />
          </div>
        </div>

        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="block text-base text-gray-500">
                {publishedAt}
              </span>
              <span className="mt-3 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                {title}
              </span>
            </h1>
          </div>
          <div className="mt-12 prose prose-action prose-lg text-gray-500 mx-auto">
            <section
              id="articleBody"
              className="markdown text-lg max-w-prose mx-auto mt-16"
            >
              <PortableText value={body} components={PortableTextComponents} />
            </section>
          </div>
          <div className="border bg-gray-50 pl-4 rounded py-4 max-w-prose mx-auto mt-16">
            <div className="font-semibold text-lg">En savoir plus :</div>
            <ul className="list-disc list-inside">
              {posts.map(sibling => {
                const { locale, title, slug, id } = getSiblingProps(sibling);
                return (
                  <li className="my-4" key={id}>
                    <span className="font-medium text-gray-500">
                      <Link
                        to={infoPath({ locale, slug })}
                        partiallyActive
                        activeClassName="font-bold text-gray-700"
                        className="hover:underline"
                      >
                        {title}
                      </Link>
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <BannerImage locale={locale} />
      <Footer locale={locale} />
    </>
  );
};

export default InfoPost;

export const pageQuery = graphql`
  query FaqArticleBySlug($slug: String!) {
    post: sanityInfo(slug: { current: { eq: $slug } }) {
      id
      locale
      title
      body: _rawBody
      description
      publishedAt(formatString: "DD MMMM YYYY", locale: "fr")
    }
  }
`;
